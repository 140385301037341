export default {
  colors: {
    primary: '#2B6CB0',
    background: '#F7FAFC',
    shape: `#F2F2FA`,
    title: `#3D3D4D`,
    text: `#4A5568`,
    components: {
      blockquote: {
        background: `#332616`,
        text: `#E1E1E6`,
      },
    },
  },
  fonts: {
    fontFace: {
      family: 'Roboto',
      file: '/fonts/Roboto-Regular',
    },
    body: 'Roboto',
  },
};